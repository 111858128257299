'use client';

import { Testimonial } from '#/.contentlayer/generated';
import Link from 'next/link';
import Image from 'next/image';

import { getStrippedUrl, getTwitterHandle } from '#/lib/utils';

export default function TestimonialCard({
  testimonial,
}: {
  testimonial: Testimonial;
}) {
  return (
    <figure className="group flex flex-col border border-accent-brd p-6 text-accent-txt-2 hover:bg-accent-bg-2 md:p-8">
      <span className="block h-14 w-14 text-8xl font-semibold text-brand opacity-60 group-hover:opacity-100">
        ‟
      </span>
      <blockquote className="flex-1">
        <p
          className="testimonial-quote"
          dangerouslySetInnerHTML={{ __html: testimonial.text }}
        ></p>
      </blockquote>
      <figcaption className="mt-6 flex items-center gap-x-2">
        <Image
          src={`/images/testimonials/${testimonial.author_img}`}
          className="h-10 w-10 rounded-full bg-accent-bg-2 object-cover"
          width={40}
          height={40}
          loading="lazy"
          alt={testimonial.author_name}
        />
        <div className="flex flex-col gap-y-1 leading-none">
          <div className="font-normal">{testimonial.author_name}</div>
          {testimonial.link_enabled 
            ? <Link
                href={`${testimonial.link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-90 hover:text-brand"
              >
                {getTwitterHandle(getStrippedUrl(testimonial.link || ''))}
              </Link>
            : <span className="opacity-90">{getTwitterHandle(getStrippedUrl(testimonial.link || ''))}</span>
          }
        </div>
      </figcaption>
    </figure>
  );
}
