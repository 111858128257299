import { SiteConfig } from '#/lib/types';

export const siteConfig: SiteConfig = {
  name: 'Bright Themes',
  title: 'Premium Ghost Themes',
  description:
    'Ghost themes for creators, publishers and businesses. Create your next blog, newsletter, directory, photography or news website with Premium Ghost themes.',
    // 'Discover the ultimate collection of premium Ghost Themes. Elevate your blog, newsletter, directory, photography or news website with our customizable, high-quality Ghost themes.',
    // 'Explore the best collection of premium Ghost Themes. High-quality, beautifully crafted, customizable Ghost themes for your blog, newsletter, directory, photography or news website.',
  url: process.env.URL || 'https://brightthemes.com',
  image: {
    src: '/images/meta/brightthemes-meta.jpg',
    width: 1200,
    height: 600,
  },
  links: {
    twitter: 'brightthemes_',
    github: 'brightthemes',
    facebook: 'bironthemes',
  },
  author: {
    name: 'Bright Themes',
    minibio: 'Bright Themes is a Premium Ghost Themes provider.',
  },
  organization: {
    name: 'Bright Themes',
    url: 'https://brightthemes.com',
    logo: {
      src: '/images/brightthemes.png',
      width: '100',
      height: '100',
    },
  },
};
