'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Customer } from '#/.contentlayer/generated';

export default function Customers({customers}:{customers:Customer[]}) {
  const [shown, setShown] = useState(false);

  // 3. Attach a scroll event handler
  useEffect(() => {
    if (window.scrollY >= 150) {
      setShown(true);
    }

    const onScroll = () => {
      if (window.scrollY >= 150) {
        setShown(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (customers.length === 0) return <></>;

  return (
    <>
      <p className="mb-4 text-center text-lg font-normal text-accent-txt-2">
        Trusted by 2500+ individuals and businesses
      </p>
      {shown && (
        <>
          <span className="absolute bottom-0 left-0 z-10 h-3/4 w-16 bg-gradient-to-r from-accent-bg-1 to-transparent"></span>
          <span className="absolute bottom-0 right-0 z-10 h-3/4 w-16 bg-gradient-to-r from-transparent to-accent-bg-1"></span>

          <div className="scrollbar-hidden relative overflow-x-scroll">
            <ul className="flex w-[calc(102px*30)] animate-marquee items-center gap-x-8 whitespace-nowrap p-2 sm:w-[calc(110px*30)] md:w-[calc(118px*30)]">
              {customers.map((customer) => (
                <li key={customer.name}>
                  <Link
                    href={`${customer.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block rounded-lg border border-accent-brd bg-accent-bg-2 p-4 shadow-none transition-all hover:bg-transparent hover:shadow-sm"
                  >
                    <Image
                      src={`/images/customers/${customer.logo}`}
                      className="h-10 w-10 rounded-md sm:h-12 sm:w-12 md:h-14 md:w-14"
                      width={60}
                      height={60}
                      loading="lazy"
                      alt={customer.name}
                    />
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="absolute top-0 ml-4 flex w-[calc(102px*30)] animate-marquee2 items-center gap-x-8 whitespace-nowrap p-2 sm:w-[calc(110px*30)] md:w-[calc(118px*30)]">
              {customers.map((customer) => (
                <li key={customer.name}>
                  <Link
                    href={`${customer.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block rounded-lg border border-accent-brd bg-accent-bg-2 p-4 shadow-none transition-all hover:bg-transparent hover:shadow-sm"
                  >
                    <Image
                      src={`/images/customers/${customer.logo}`}
                      className="h-10 w-10 rounded-md sm:h-12 sm:w-12 md:h-14 md:w-14"
                      width={60}
                      height={60}
                      loading="lazy"
                      alt={customer.name}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
}
