'use client';

import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Icon from '#/components/icon';
import Image from 'next/image';
import { Theme } from '#/.contentlayer/generated';
import { Caveat } from 'next/font/google';

const caveat = Caveat({ subsets: ['latin'] });

export default function Visual({themes}: {themes: Theme[]}) {
  const [activeTheme, setActiveTheme] = useState(themes[0].slug);
  const themeClicked = useRef(false);

  useEffect(() => {
    let count = 0;

    const changeTab = () => {
      if (themeClicked.current) return;
      setActiveTheme(themes[count].slug);
      count = count < themes.length - 1 ? count + 1 : 0;

      setTimeout(() => {
        changeTab();
      }, 20000);
    };

    changeTab();
  }, [themeClicked]); //adding themes will break the automatic tab change

  const diffToRelease = new Date().getTime() - new Date(themes[0].published_date || '').getTime();
  const diffToReleaseDays = diffToRelease / (1000 * 3600 * 24);

  return (
    <div className="mx-auto mb-16 max-w-5xl px-4 lg:px-0 hidden sm:block relative">
      {diffToReleaseDays < 60 &&
        <div className={`animate-bounce absolute flex flex-col items-end left-[8rem] -top-[5.5rem] z-[50] text-accent`}
          style={{'--color-accent': `var(--color-${themes[0].slug})`} as React.CSSProperties}
        >
          <span className={`font-medium text-2xl mb-0.5 -mr-3 italic ${caveat.className}`}>New</span>
          <svg className="w-16 h-16 rotate-180" viewBox="0 0 77 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33755 84.3973C0.297616 62.7119 2.93494 39.8181 19.4192 23.8736C28.2211 15.3599 42.4944 12.5723 47.6281 26.2359C51.1245 35.5419 51.542 51.9945 41.0605 57.0865C29.486 62.7095 40.2945 35.2221 41.9942 32.4952C49.9497 19.7313 59.7772 11.6122 72.2699 3.78281C76.9496 0.849879 73.7108 0.477284 70.0947 1.13476C66.9572 1.7052 63.4035 2.43717 60.5291 3.81975C59.6524 4.24143 65.7349 2.73236 66.6827 2.44768C70.7471 1.22705 75.4874 -0.0219285 75.9527 5.60812C76.0274 6.5127 75.9956 14.9844 74.7481 15.2963C74.099 15.4586 71.0438 10.27 70.4642 9.65288C66.6996 5.64506 63.5835 4.42393 58.2726 5.11792" stroke="currentColor" strokeWidth="2.75" strokeLinecap="round"></path>
          </svg>
        </div>
      }
      <div className="overflow-hidden rounded-xl border border-accent-brd bg-accent-bg-1 drop-shadow-2xl">
        <div className="scrollbar-hidden relative flex h-10 w-full items-center justify-start overflow-x-scroll rounded-t-lg border-b border-accent-brd bg-accent-bg-2 px-2 pr-0 md:overflow-x-visible">
          <div className="ml-2 mr-4 flex gap-x-2">
            <span className="h-2.5 w-2.5 rounded-full border-2 border-accent-brd md:h-3 md:w-3"></span>
            <span className="h-2.5 w-2.5 rounded-full border-2 border-accent-brd md:h-3 md:w-3"></span>
            <span className="h-2.5 w-2.5 rounded-full border-2 border-accent-brd md:h-3 md:w-3"></span>
          </div>

          {themes.map((theme, index) => (
            <button
              key={theme.slug}
              className={`group flex h-full items-center border-b-0 border-l border-accent-brd px-4 text-sm font-normal hover:text-accent-txt-1
              ${
                theme.slug === activeTheme
                  ? ' bg-accent-bg-1 text-accent-txt-1'
                  : ' text-accent-txt-2'
              }
              ${themes.length === index + 1 ? ' border-r' : ''}`}
              onClick={() => {
                setActiveTheme(theme.slug);
                themeClicked.current = true;
              }}
            >
              <span
                className={`mr-2 block${
                  theme.slug === activeTheme ? `` : ''
                }`}
                style={{'--color-accent': `var(--color-${theme.slug})`,} as React.CSSProperties}
              >
                <Icon name="star-filled" className={`h-3 w-3${
                  theme.slug === activeTheme ? ' text-accent' : ' text-accent-brd'
                }`}></Icon>
              </span>
              {theme.name}
            </button>

          ))}
          <span className="flex-1"></span>
          <Link
            href="/themes/"
            className="ml-4 flex h-full items-center self-center whitespace-pre border-l border-accent-brd bg-accent-bg-2 px-3 text-sm font-normal text-accent-txt-2 hover:bg-accent-bg-1 hover:text-accent-txt-1 md:px-4"
          >
            All themes
            <Icon
              name="arrow-right"
              className="ml-1 h-3 w-3"
              strokeWidth="2.5"
            ></Icon>
          </Link>
        </div>
        <ul className="relative aspect-[16/9] overflow-hidden rounded-b-lg">
          {themes.map((theme, index) => (
            <li
              key={theme.slug}
              className={`${
                theme.slug === activeTheme
                  ? 'z-1 opacity-100'
                  : 'z-[-1] opacity-0'
              } absolute h-full w-full duration-500 before:absolute before:left-0 before:top-0 before:z-[0] before:h-full before:w-full before:bg-accent before:opacity-5 before:content-['']`}
              style={
                {
                  '--color-accent': `var(--color-${theme.slug})`,
                } as React.CSSProperties
              }
            >
              <Link
                href={`/themes/${theme.slug}/`}
                className={`absolute left-0 top-0 h-full w-full ${
                  theme.slug === activeTheme ? '' : ''
                }`}
              >
                <Image
                  src={`/images/themes/${
                    theme.mockup_image ? theme.mockup_image : theme.image
                  }`}
                  className={`object-contain object-top`}
                  fill={true}
                  sizes="(max-width: 1080px) 90vw, 960px"
                  loading={index === 0 ? 'eager' : 'lazy'}
                  priority={index === 0 ? true : false}
                  alt={`${theme.name} Ghost Theme`}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
