import {
  WebSite,
  WebPage,
  Product,
  Article,
  BlogPosting,
  AggregateRating,
  WithContext,
  Offer
} from 'schema-dts';

import { siteConfig } from '#/config/site';

export function createSiteSchema({
  url,
  description,
  image,
  imageWidth,
  imageHeight,
}: {
  url?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
}) {
  const site: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: siteConfig.url, //url ? `${siteConfig.url}${url}/` :
    description: siteConfig.description, //description ? description :
    // mainEntityOfPage: {
    //   '@type': 'WebPage',
    //   '@id': siteConfig.url,
    // },
    // image: {
    //   '@type': 'ImageObject',
    //   url: image
    //     ? `${siteConfig.url}/images/meta/${image}`
    //     : siteConfig.metaImage,
    //   width: imageWidth ? imageWidth : '1200',
    //   height: imageHeight ? imageHeight : '600',
    // },
  };

  return site;
}

export function createPageSchema({
  url,
  description,
  image,
  imageWidth,
  imageHeight,
}: {
  url?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
}) {
  const page: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: url ? `${siteConfig.url}${url}/` : siteConfig.url,
    description: description ? description : siteConfig.description,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteConfig.url,
    },
    image: {
      '@type': 'ImageObject',
      url: image
        ? `${siteConfig.url}/images/meta/${image}`
        : `${siteConfig.url}${siteConfig.image.src}`,
      width: imageWidth ? imageWidth : '1200',
      height: imageHeight ? imageHeight : '600',
      creator: {
        '@type': 'Person',
        name: siteConfig.organization.name,
      },
    },
  };

  return page;
}

export function createProductSchema({
  url,
  title,
  description,
  image,
  price,
  aggregateRating,
}: {
  url?: string;
  title?: string;
  description?: string;
  image?: string;
  price?: string;
  aggregateRating?: {
    ratingValue: number;
    ratingCount: number;
  };
}) {
  const ratings:AggregateRating = {
    '@type': 'AggregateRating',
    'ratingValue': aggregateRating ? aggregateRating.ratingValue : 0,
    'reviewCount': aggregateRating ? aggregateRating.ratingCount : 0
  }

  const offer: Offer = {
    '@type': 'Offer',
    // availability: 'https://schema.org/InStock',
    price: price,
    priceCurrency: 'USD',
    url: `${siteConfig.url}/${url}`,
  }

  const product: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    category: 'Ghost Themes',
    url: `${siteConfig.url}${url}/`,
    name: title,
    description: description,
    image: `${siteConfig.url}/images/meta/${image}`,
    offers: offer
  };

  const productSchema = aggregateRating && aggregateRating.ratingCount > 0 
    ? { ...product, aggregateRating: ratings } : product 

  return productSchema;
}

export function createArticleSchema({
  url,
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  keywords,
  datePublished,
  dateModified,
}: {
  url?: string;
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
  datePublished?: string;
  dateModified?: string;
  imageWidth?: string;
  imageHeight?: string;
}) {
  const article:WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    keywords: keywords,
    headline: title,
    url: `${siteConfig.url}${url}`,
    datePublished: datePublished,
    dateModified: dateModified,
    description: description,
    about: {
      '@type': 'CreativeWork',
      name: title,
      description: description,
    },
    author: {
      '@type': 'Person',
      name: siteConfig.organization.name,
      sameAs: [
        siteConfig.url,
        `https://facebook.com/${siteConfig.links.facebook}`,
        `https://twitter.com/${siteConfig.links.twitter}`,
      ],
    },
    image: {
      '@type': 'ImageObject',
      url: image
        ? `${siteConfig.url}/images/meta/${image}`
        : `${siteConfig.url}${siteConfig.image.src}`,
      width: imageWidth,
      height: imageHeight,
      creator: {
        '@type': 'Person',
        name: siteConfig.organization.name,
      },
    },
    publisher: {
      '@type': 'Organization',
      name: siteConfig.organization.name,
      logo: {
        '@type': 'ImageObject',
        url: `${siteConfig.url}${siteConfig.organization.logo.src}`,
        width: siteConfig.organization.logo.width,
        height: siteConfig.organization.logo.height,
        creator: {
          '@type': 'Person',
          name: siteConfig.organization.name,
        },
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${siteConfig.url}/blog/`,
    },
  };

  return article;
}

export function createBlogPostingSchema({
  url,
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  keywords,
  datePublished,
  dateModified,
}: {
  url?: string;
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
  datePublished?: string;
  dateModified?: string;
  imageWidth?: string;
  imageHeight?: string;
}) {
  const blogPost: WithContext<BlogPosting> = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    keywords: keywords,
    headline: title,
    url: `${siteConfig.url}${url}`,
    datePublished: datePublished,
    dateModified: dateModified,
    description: description,
    about: {
      '@type': 'CreativeWork',
      name: title,
      description: description,
    },
    author: {
      '@type': 'Person',
      name: siteConfig.organization.name,
      sameAs: [
        siteConfig.url,
        `https://facebook.com/${siteConfig.links.facebook}`,
        `https://twitter.com/${siteConfig.links.twitter}`,
      ],
    },
    image: {
      '@type': 'ImageObject',
      url: image
        ? `${siteConfig.url}/images/meta/${image}`
        : `${siteConfig.url}${siteConfig.image.src}`,
      width: imageWidth,
      height: imageHeight,
      creator: {
        '@type': 'Person',
        name: siteConfig.organization.name,
      },
    },
    publisher: {
      '@type': 'Organization',
      name: siteConfig.organization.name,
      logo: {
        '@type': 'ImageObject',
        url: `${siteConfig.url}${siteConfig.organization.logo.src}`,
        width: siteConfig.organization.logo.width,
        height: siteConfig.organization.logo.height,
        creator: {
          '@type': 'Person',
          name: siteConfig.organization.name,
        },
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${siteConfig.url}/blog/`,
    },
  };

  return blogPost;
}
