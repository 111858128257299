'use client';

import { usePathname } from 'next/navigation';
import {
  createSiteSchema,
  createPageSchema,
  createProductSchema,
  createArticleSchema,
  createBlogPostingSchema,
} from '#/lib/structured';
import Script from 'next/script';

export default function StructuredData({
  webSite,
  webPage,
  product,
  article,
  blogPosting,
  image,
  title,
  description,
  price,
  datePublished,
  dateModified,
  aggregateRating
}: {
  webSite?: boolean;
  webPage?: boolean;
  product?: boolean;
  article?: boolean;
  blogPosting?: boolean;
  title?: string;
  description?: string;
  image?: string;
  price?: string;
  datePublished?: string;
  dateModified?: string;
  aggregateRating?: {
    ratingValue: number;
    ratingCount: number;
  };
}) {
  const jsonLd: any = [];
  const path = usePathname();

  if (webSite) {
    const siteSchema = createSiteSchema({
      url: path,
      description: description,
      image: image,
    });
    jsonLd.push(siteSchema);
  }

  if (webPage) {
    const pageSchema = createPageSchema({
      url: path,
      description: description,
      image: image,
    });
    jsonLd.push(pageSchema);
  }

  if (product) {
    const productSchema = createProductSchema({
      url: path,
      description: description,
      image: image,
      title: title,
      price: price,
      aggregateRating: aggregateRating
    })
    jsonLd.push(productSchema);
  }

  if (article) {
    const articleSchema = createArticleSchema({
      url: path,
      description: description,
      image: image,
      imageWidth: "1200",
      imageHeight: "600",
      title: title,
      datePublished: datePublished,
      dateModified: dateModified,
    });
    jsonLd.push(articleSchema);
  }

  if (blogPosting) {
    const blogSchema = createBlogPostingSchema({
      url: path,
      description: description,
      image: image,
      imageWidth: "1200",
      imageHeight: "600",
      title: title,
      datePublished: datePublished,
      dateModified: dateModified,
    });
    jsonLd.push(blogSchema);
  }

  return (
    <>
      <Script
        // strategy="beforeInteractive"
        id="json+ld"
        type='application/ld+json'
      >
        {JSON.stringify(jsonLd)}
      </Script>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      /> */}
    </>
  );
}
