// getFormattedDate
export function getFormattedDate(date?: string) {
  const datum = date ? new Date(date) : new Date();

  return datum.toLocaleDateString('en', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

export function getStrippedUrl(url: string) {
  const strippedUrl = url.replace(/^https?:\/\//, '')
                        .replace(/^www./, '')
                        .replace(/\/+$/, '')

  return strippedUrl
}

export function getTwitterHandle(url: string) {
  const twitter = url.split('twitter.com/')
  const handle = twitter[1] ? `@${twitter[1]}` : url
  return handle
}

export function removeTrailingSlash(url: string) {
  const updateURL = url.replace(/\/+$/, '')
  return updateURL
}

export function getCapitalizedString(str: string) {
  const parts = str.split('-')
  const capitalized = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
  // const capitalized = data.charAt(0).toUpperCase() + data.slice(1)
  return capitalized
}
